import React, { Component } from "react"
import cn from "classnames"
import { buildURL } from "react-imgix"

// Components
import Title from "./title"
import Button from "./button"
import Text from "./text"
import VideoImageBlock from "./videoImageBlock"
import Rating from "./rating"
import EventAnchor from "./eventAnchor"
import ProductButtons from "./newProductButtons"

// Styles
import styles from "../styles/scss/components/productInformation.module.scss"

export default class Index extends Component {
    state = {
        selectedIndex: 0,
        selected: "",
        imgHover: "",
        isZoomed: false,
        backgroundPosition: { x: 50, y: 50 },
    }
    handleClick(selected, selectedIndex) {
        this.setState({ selectedIndex, selected })
    }
    handleArrowClick(type, images, lastIndex) {
        if (lastIndex === 0 && type === "previous") return
        const index = type === "next" ? lastIndex + 1 : lastIndex - 1
        if (index < images.length) {
            const selectedIndex =
                images[index] !== "undefined" ? index : lastIndex
            const selected = images[selectedIndex].url
                ? images[selectedIndex].url
                : images[selectedIndex].video.thumbnailUrl
            this.setState({ selected, selectedIndex })
        }
        if (type === "next") {
            document.getElementById("SliderImagesContainer").scrollLeft += 100
        } else {
            document.getElementById("SliderImagesContainer").scrollLeft -= 100
        }
    }
    handleMouseEnter(imgHover) {
        this.setState({ imgHover })
    }
    handleMouseLeave() {
        this.setState({ imgHover: "" })
    }

    handleImageZoom = () => {
        this.setState((prevState) => ({
            isZoomed: !prevState.isZoomed,
        }));
    };

    handleMouseMove = (e) => {
        const { isZoomed } = this.state; 
        if (isZoomed) {
            const { target, clientX, clientY } = e; 
            const { left, top, width, height } = target.getBoundingClientRect(); 
            const x = ((clientX - left) / width) * 100;
            const y = ((clientY - top) / height) * 100; 
    
            this.setState({
                backgroundPosition: { x, y },
            });
        }
        else {
            this.setState({
                backgroundPosition: { x: 50, y: 50 },
            });
        }
    };

    goToProductInUse = () => {
        const { goToProductInUse } = this.props
        if (goToProductInUse) {
            goToProductInUse()
        }
    }

    handleOnClickReadReviews = e => {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        if (e.key !== "Tab" && e.key !== "Shift") {
            const { goToCustomerReviews } = this.props
            if (goToCustomerReviews) {
                goToCustomerReviews()
            }
        }
    }

    render() {
        const isNewLayout = true
        const { product, reviewStats, children, productInUse } = this.props
        const { selectedIndex, selected, imgHover, isZoomed, backgroundPosition } = this.state
        const selectedImage =
            imgHover ||
            selected ||
            (product.images && product.images.length > 0
                ? product.images[0].url
                : "")
        const initialSelected =
            !selected && product.images && product.images.length > 0
        const sliderContent = [].concat(product.images).concat(product.videos)
        const sku =
            product && product.skus && product.skus.length > 0
                ? product.skus[0]
                : null

        return (
            <div
                className={
                    isNewLayout ? styles.containerNewLayout : styles.container
                }
            >
                <div
                    className={
                        isNewLayout
                            ? styles.imageGalleryNewLayout
                            : styles.imageGallery
                    }
                >
                    <div className={styles.showMobile}>
                        <div className={styles.body}>{children}</div>
                    </div>

                    {productInUse && productInUse.length > 0 && (
                        <div className={cn(
                            styles.mobileAnchor,
                            styles.seeProductInUse
                        )} >
                            <EventAnchor
                                text="See Product In Use >>"
                                noMargin
                                event={() => this.goToProductInUse()}
                            />
                        </div>
                    )}

                    <div className={styles.imageGalleryWrapperNewLayout}>
                        <svg
                            onClick={this.handleArrowClick.bind(
                                this,
                                "previous",
                                sliderContent,
                                selectedIndex
                            )}
                            className={styles.customArrowsSvg}
                            width="18"
                            height="28"
                            viewBox="0 0 18 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17 2L3 14L17 26"
                                stroke="#1C3687"
                                strokeWidth="3"
                            />
                        </svg>
                        <div
                            id={"SliderImagesContainer"}
                            className={
                                isNewLayout
                                    ? [
                                        cn(
                                            styles.imageListNewLayout,
                                            sliderContent.length <= 4
                                                ? styles.fourOrLess
                                                : undefined,
                                            sliderContent.length <= 3
                                                ? styles.threeOrLess
                                                : undefined
                                        ),
                                    ]
                                    : styles.imageList
                            }
                        >
                            {isNewLayout &&
                                sliderContent &&
                                sliderContent.map((v, i) =>
                                    typeof v.video !== "undefined" ? (
                                        <VideoImageBlock
                                            key={v.video.providerUid}
                                            title={v.title}
                                            imageClass={styles.videoImage}
                                            containerClass={
                                                styles.videoContainer
                                            }
                                            hideBody
                                            youtubeVideoId={v.video.providerUid}
                                            image={v.video.thumbnailUrl}
                                        />
                                    ) : (
                                        <div
                                            tabIndex={0}
                                            role="button"
                                            key={v.id}
                                            className={cn({
                                                [styles.selected]:
                                                    selected === v.url ||
                                                    imgHover === v.url ||
                                                    (initialSelected &&
                                                        i === 0),
                                            })}
                                            onMouseEnter={this.handleMouseEnter.bind(
                                                this,
                                                v.url
                                            )}
                                            onMouseLeave={this.handleMouseLeave.bind(
                                                this
                                            )}
                                            onClick={this.handleClick.bind(
                                                this,
                                                v.url,
                                                i
                                            )}
                                            onKeyDown={this.handleClick.bind(
                                                this,
                                                v.url,
                                                i
                                            )}
                                        >
                                            <img
                                                alt="Product Display"
                                                src={buildURL(v.url, {
                                                    fit: "clamp",
                                                    width: 150,
                                                    height: 150,
                                                    fm: "png",
                                                    bg: "transparent",
                                                })}
                                            />
                                        </div>
                                    )
                                )}
                            {!isNewLayout &&
                                product.images &&
                                product.images.map((v, i) => (
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        key={v.id}
                                        className={cn({
                                            [styles.selected]:
                                                selected === v.url ||
                                                imgHover === v.url ||
                                                (initialSelected && i === 0),
                                        })}
                                        onMouseEnter={this.handleMouseEnter.bind(
                                            this,
                                            v.url
                                        )}
                                        onMouseLeave={this.handleMouseLeave.bind(
                                            this
                                        )}
                                        onKeyDown={this.handleClick.bind(
                                            this,
                                            v.url,
                                            i
                                        )}
                                        onClick={this.handleClick.bind(
                                            this,
                                            v.url,
                                            i
                                        )}
                                    >
                                        <img
                                            alt="Product Display"
                                            src={buildURL(v.url, {
                                                fit: "clamp",
                                                width: 80,
                                                height: 80,
                                                fm: "png",
                                            })}
                                        />
                                    </div>
                                ))}
                            {!isNewLayout &&
                                product.videos &&
                                product.videos.map((v, i) => (
                                    <VideoImageBlock
                                        key={v.video.providerUid}
                                        title={v.title}
                                        imageClass={styles.videoImage}
                                        containerClass={styles.videoContainer}
                                        hideBody
                                        youtubeVideoId={v.video.providerUid}
                                        image={v.video.thumbnailUrl}
                                    />
                                ))}
                        </div>
                        <svg
                            onClick={this.handleArrowClick.bind(
                                this,
                                "next",
                                sliderContent,
                                selectedIndex
                            )}
                            className={styles.customArrowsSvg}
                            width="18"
                            height="28"
                            viewBox="0 0 18 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 26L15 14L0.999998 2"
                                stroke="#1C3687"
                                strokeWidth="3"
                            />
                        </svg>
                    </div>
                    {isNewLayout && product.upc && (
                        <div className={styles.productUpc}>
                            {`UPC ${product.upc}`}
                        </div>
                    )}
                    <div 
                        className={
                            isNewLayout
                                ? styles.selectedImageNewLayout
                                : styles.selectedImage
                        }
                    >
                        <div 
                            className={styles.imageContainer}
                            onClick={this.handleImageZoom}
                            onMouseMove={this.handleMouseMove}
                            style={
                                isZoomed ? {
                                    backgroundImage: `url(${buildURL(selectedImage, {
                                        width: 440,
                                        bg: "transparent",
                                        fm: "png",
                                    })})`,
                                    backgroundOpacity: 1,
                                    backgroundPosition: `${backgroundPosition.x}% ${backgroundPosition.y}%`,
                                    cursor: 'zoom-out',
                                } : 
                                { 
                                    cursor: 'zoom-in', 
                                    backgroundOpacity: 0,
                                }
                            }
                        >
                            {selectedImage && (
                                <img
                                    alt="Product Selected"
                                    src={buildURL(selectedImage, {
                                        bg: "transparent",
                                        fm: "png",
                                    })}
                                    style={isZoomed ? { opacity: 0 } : { opacity:1 }}
                                />
                            )}
                        </div>

                        {!isNewLayout ? (
                            product.showOtherSizesOnDetail ? (
                                <table
                                    className={cn(styles.showDesktop)}
                                    style={{
                                        fontSize: "15px",
                                        marginLeft: "20px",
                                        marginRight: "20px",
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <td className="text-right">
                                                <Text
                                                    value={product.subtitle}
                                                />
                                            </td>
                                            <td>
                                                <Text
                                                    value={"UPC " + product.upc}
                                                />
                                            </td>
                                        </tr>
                                        {product.otherProductSizes.map(p => {
                                            return (
                                                <tr key={p.id}>
                                                    <td className="text-right">
                                                        <Text
                                                            value={p.subtitle}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Text
                                                            value={
                                                                "UPC " + p.upc
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <div className={styles.showDesktop}>
                                    {product.upc && (
                                        <Text
                                            value={"UPC " + product.upc}
                                            style={{ fontSize: "15px" }}
                                        />
                                    )}
                                </div>
                            )
                        ) : (
                            false
                        )}
                        {!isNewLayout && (
                            <div
                                className={styles.showDesktop}
                                style={{ marginTop: "15px" }}
                            >
                                <div
                                    className={cn(
                                        styles.priceSpider,
                                        "ps-widget"
                                    )}
                                    ps-sku={sku}
                                >

                                    <span className="ps-button-label">
                                        Where to buy
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.informationNewLayout}>
                    <div className={styles.productTitle}>
                        {product.displayName && (
                            <Title
                                value={product.displayName}
                                style={{ textAlign: "left" }}
                                titleType={"h1"}
                            />
                        )}
                        {
                            product.slug === "industrial-strength-cleaner-degreaser-55gal" ?
                                <div className={styles.whereToBuyContainer}>
                                    <p className={styles.specialOrderTitle}>FOR SPECIAL ORDER</p>
                                    <p className={styles.specialOrderDescription}>Please contact a WD-40 sales representative for order placement or contact our customer service for further assistance.</p>
                                </div> :
                                <div className={styles.whereToBuyContainer}>
                                    <ProductButtons
                                        className={styles.whereToBuy}
                                        product={product}
                                        verticalButtons={true}
                                        withoutAnchor={true}
                                        stikyButton={true}
                                        displayName="Where to buy"
                                    />
                                </div>
                        }

                    </div>
                    <div className={styles.subtitle}>
                        <div className={styles.subTitle}>
                            {product.subtitle}
                        </div>
                    </div>
                    <div className={styles.reviewsSection}>
                        <div className={styles.showDesktop}>
                            <a className={styles.reviewLink} href="#reviews">
                                <Rating
                                    value={reviewStats}
                                    bold
                                    spacing
                                    newStyle
                                />
                            </a>
                        </div>
                        <div
                            className={styles.showMobile}
                            style={{ justifyContent: "flex-start" }}
                        >
                            <a className={styles.reviewLink} href="#reviews">
                                <Rating value={reviewStats} bold newStyle />
                            </a>
                        </div>
                        <div className={styles.productLinks}>
                            <div className={styles.review}>
                                {product.bazaarVoiceId && (
                                    <div
                                        className={styles.review_btn}
                                        tabIndex={0}
                                        role="button"
                                        onKeyDown={this.handleOnClickReadReviews}
                                        onClick={this.handleOnClickReadReviews}
                                    >
                                        {"READ REVIEWS  >>"}
                                    </div>
                                )}
                                <div className={styles.showDesktop}>
                                    {
                                        this.props.seeProductInUse &&
                                        this.props.seeProductInUse()
                                    }

                                </div>
                            </div>

                        </div>

                    </div>
                    <div
                        className={styles.showMobile}
                        style={{ justifyContent: "flex-start" }}
                    >
                        {
                            product.slug === "industrial-strength-cleaner-degreaser-55gal" ? 
                            <div className={styles.whereToBuyContainerMobile}>
                                    <p className={styles.specialOrderTitle}>FOR SPECIAL ORDER</p>
                                    <p className={styles.specialOrderDescription}>Please contact a WD-40 sales representative for order placement or contact our customer service for further assistance.</p>
                                </div> :
                            <ProductButtons
                                className={styles.whereToBuy}
                                product={product}
                                verticalButtons={true}
                                withoutAnchor={true}
                                displayName="Where to buy"
                            />
                        }
                    </div>
                    <div className={styles.showDesktop}>
                        <div className={styles.body}>{children}</div>
                    </div>
                </div>
            </div>
        )
    }
}
